export default {
	unitName: "Mention",
	onConnected() {
		if(!this.con){
			this.con=true
			let url = new URL('/user_pages', window.location)
			url.searchParams.set('username', this.el.dataset.id)
			this.el.addEventListener('click', () => otty.goto(url))
			//the username will then redirect to the /user_pages/slug path.
		}
	}
}