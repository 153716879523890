export default {
	unitName: "BgHandler",
	onConnected(){
		let bg = JSON.parse( this.el.ds.data )
		
		let im = this.el.qs('img')
		console.log(im, bg, midflip.isDm(), )
		if(midflip.isDm()){
			im.setAttribute('src', bg.dark)
		} else {
			im.setAttribute('src',  bg.light)
		}
		let prom = new Promise((resolve) => {
			im.addEventListener('load', resolve)
			if(im.complete){resolve()}
		})

		if (document.getElementById('sidebar')){
			im.classList.add('sidebar_mountains')
		}

		prom.then(() => {
			console.log("show")
			im.classList.remove('hidden')
		})
	}
}